@import "../../global/styles/variables.scss";

.OAniPlataform {
  border: 2px solid $bold-gray-2;
  padding: 10px;
  &__Name {
    font-size: 22px;
  }

  &__Line {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__Platform {
    font-size: 18px;
  }

  &__Separator {
    margin: 10px 0;
    width: 100%;
    height: 2px;
    background-color: $bold-gray-2;
  }

  &__PlatformDisable {
    margin-left: 20px;
    width: 180px;
  }
}

@import "../../global/styles/variables.scss";

.OAniMonitor {
  display: flex;
  align-items: center;
  &__Parallel {
    margin-right: 7px;
    min-width: 120px;
    span {
      font-family: "Avenir-Heavy";
    }
  }
  &__Lighthouse {
    height: 35px;
    border-radius: 800px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: "Avenir-Heavy";

    &--red {
      background-color: #d63643;
    }
    &--yellow {
      background-color: #fec32d;
    }
    &--green {
      background-color: #6bc235;
    }
  }
}

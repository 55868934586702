.OError {
  border-top: 3px solid #ff0000;
  text-align: center;
  padding-top: 30px;
  h2{
    font-size: 28px;
    margin-bottom: 20px;
  }
  li{
    font-size: 24px;
    margin-bottom: 10px;
  }
}

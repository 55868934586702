.OInputForm {
  display: flex;
  flex-direction: column;
  position: relative;
  &__Label {
    font-size: 18px;
    margin-bottom: 10px;
  }
  &__Input {
    height: 50px;
    border: 1px solid hsl(0, 0%, 80%);
    width: 100%;
    font-size: 16px;
    padding: 0;
    outline: none;
    border-radius: 4px;
    padding: 2px 8px;
    box-sizing: border-box;
  }
  &__Link {
    position: absolute;
    left: 0;
    bottom: -25px;
    color: #5353bb;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &__Clearable {
    fill: #cccccc;
    stroke: #cccccc;
    position: absolute;
    stroke-width: 0;
    right: 10px;
    bottom: 15px;
    cursor: pointer;
    &:hover {
      fill: #666666;
      stroke: #666666;
    }
  }
  &--disable {
    opacity: 0.3;
    pointer-events: none;
  }
}

@import "../../global/styles/variables.scss";

.Console {
  &__Main {
    display: flex;
    position: relative;
  }

  &__Menu {
    position: relative;
    width: 17%;
    min-width: 250px;
    height: calc(100vh - 76px); //TODO - Colocar variavel
    background-color: #363636;
    text-align: left;
    color: #fff;
    font-size: 18px;
    box-sizing: border-box;

    &__Content {
      overflow-y: auto;
      padding: 40px 20px 10px;
      height: calc(100vh - 76px);
      box-sizing: border-box;
    }

    .OLoading {
      color: #000;
    }
  }

  h1 {
    font-size: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__PageTitle {
    font-size: 30px;
    margin-bottom: 20px;
  }

  &__Title {
    margin-bottom: 10px;
  }

  &__DateFinal {
    margin-top: 20px;
  }

  &__Search {
    margin-top: 20px;
    width: 100%;
  }

  &__Resume {
    width: 100%;
    max-width: 90%;
    margin-bottom: 20px;
  }

  &__Content {
    overflow: scroll;
    // min-width: 1000px;
    height: calc(100vh - 76px); //TODO - Colocar variavel
    width: calc(100% - 17%);
    padding: 0 0 20px 0;
    box-sizing: border-box;
    text-align: left;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
      max-width: 1050px;
    }

    &__Full {
      width: 100%;
      min-height: 500px;
    }
  }

  &__Loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 78%;
    font-size: 30px;
    position: relative;

    &__NoFilters {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 10;
    }
  }

  &__Field {
    margin-top: 20px;
  }

  &__TableData {
    max-width: 90%;
    width: 100%;
    // width: 90%;
    // min-width: 1000px;
    // margin-bottom: 90px;
  }

  &__TableContent {
    .OTableGrid {
      td {
        word-break: break-all;
      }
    }
    .OTableGrid__Header, .OTableGrid__Columns {
      grid-template-columns: 1fr minmax(0, 1.5fr) 1fr 1fr 1fr;
      // grid-auto-columns: minmax(0, 1fr);
      // grid-auto-flow: column;
    }
  }

  &__TableHeader {
    display: grid;
    font-size: 17px;
    font-family: "Avenir-Heavy";
    padding: 0 16px;
    margin-bottom: 10px;
    align-items: center;
  }

  .OTableGrid__Content, .OTableGrid__HeaderColumns {
    padding: 0 10px 0 10px;
    box-sizing: border-box;
    // word-break: break-all;
    // border: 1px solid #cccccc;
    // border-radius: 8px;
    // overflow: hidden;
  }

  &__Error {
    color: #d63643;
    font-size: 18px;
  }

  &__CsvError {
    .OModal__Container {
      min-width: 650px;
      min-height: 230px;
      color: #d63643;
    }
    .OModal__Info {
      fill: #d63643;
    }
  }

  .OPagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  &__Lock {
    text-align: center;
    svg {
      width: 14px;
      height: 16px;
    }
  }

  &__MonitorOptions {
    border-radius: 6px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 20px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-sizing: border-box;
    // min-width: 545px;
    align-self: flex-end;
    // margin-right: 2.5%;
    z-index: 1000;

    .LastUpdate {
      margin-left: 20px;
    }
  }

  &__Children {
    font-size: 16px;
    display: flex;
    color: #686868;
    align-items: baseline;
    p {
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
    }
    span {
      font-family: "Avenir-Heavy";
    }
  }
  
  &__Monitoring {
    display: flex;
    align-items: center;
    
    cursor: pointer;
    user-select: none;
    p {
      margin-right: 0;
    }
    .OToogle {
      height: 19px;
      margin-left: 15px;
    }
  }
}
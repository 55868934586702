@import "../../global/styles/variables.scss";

.OMonitorItem {
  &__Container {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 3px 6px #00000029;
  }
  &__Title {
    margin-bottom: 10px;
    color: rgb(136, 136, 136);
  }
  &__Percentage {
    color: #fff;
    font-size: 32px;
    padding: 25px;
    position: relative;
    box-sizing: border-box;
    span {
      font-size: 22px;
      margin-left: 5px;
    }
    &--red {
      background-color: red;
    }
    &--yellow {
      background-color: yellow;
    }
    &--green {
      background-color: green;
    }
  }
  &__PercentageLabel {
    position: relative;
    z-index: 20;
  }
  &__PercentageBar {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    &--red {
      background-color: rgb(189, 0, 0);
    }
    &--yellow {
      background-color: rgb(204, 204, 0);
    }
    &--green {
      background-color: rgb(1, 77, 1);
    }
  }
  &__Total {
    padding: 15px;
    box-sizing: border-box;
    color: rgb(153, 153, 153);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__Qtd {
    font-family: "Avenir-Heavy";
  }
  &__More {
    text-decoration: underline;
    color: rgb(56, 56, 252);
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}

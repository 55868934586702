.GeralConfig {
  &__Error {
    color: #d63643;
    font-family: "Avenir-Roman";
    font-size: 18px;
    margin-top: 10px;
  }

  &__Form {
    height: 90%;
  }

  &__TimeContainer{
    margin-bottom: 20px;
  }

  &__TimeInput, &__PageInput {
    display: flex;
    align-items: flex-end;
    .OInputForm {
      width: 70%;
    }
    span {
      font-size: 22px;
      margin-left: 10px;
      color: #686868;
      font-family: "Avenir-Heavy";
    }
  }

  &__TimeOpts {
    width: 70%;
    display: flex;
  }

  &__TimeOpt {
    border: 2px solid #686868;
    padding: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: #686868;
      color: #fff;
    }
  }

  &__Controller {
    display: flex;
    .Button {
      width: 250px;
      margin-right: 20px;
    }
  }

  &__PageContainer{
    margin-top: 30px;
  }
}

.OInput {

  color: #333;
  width: 100%;
  box-sizing: border-box;

  &--error {
    border-color: #ff0000;
    background-color: rgba(255, 0, 0, 0.1);
  }

  &__Label {
    margin-bottom: 10px;
    margin: 1px solid transparent;
    text-align: left;
    display: block;
    font-size: 14px;
    // font-size: 0;
    // opacity: 0;
    transition: all .3s ease-out;
    &--show {
      opacity: 1;
      // padding-top: 10px;
      // font-size: initial;
    }
  }

  .Input {
    display: flex;
    min-height: 45px;
    border-radius: 4px;
    border: 2px solid #ccc;
    padding: 0 10px;

    input, textarea {
      width: 100%;
      font-size: 16px;
      border: none;
      background-color: transparent;
      padding: 0;
      outline: none;
      font-family: 'Avenir-Regular';
    }

    textarea {
      padding: 10px 0;
    }

    svg {
      cursor: pointer;
      width: 20px;
    }
  }

  &__Icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

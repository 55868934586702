@import "../../global/styles/variables.scss";

.OButtonIcon {
  cursor: pointer;
  margin-left: 17px;
  display: flex;
  align-items: center;
  color: $orange;
  border: 2px solid $orange;
  box-sizing: border-box;
  padding: 5px;
  width: fit-content;
  border-radius: 5px;
  svg {
    fill: $orange;
    width: 26px;
    margin-right: 10px;
  }
  span {
    font-family: "Avenir-Roman";
    position: relative;
    font-size: 16px;
  }
  &:hover {
    opacity: 0.6;
  }
  &--disable {
    pointer-events: none;
    opacity: 0.3;
    filter: grayscale(100%);
  }
}

@import "../../global/styles/variables.scss";

.OTabs {
  display: flex;
  .OTabItem {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}

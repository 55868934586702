@import "../../global/styles/variables.scss";

.OAniLiveMonitor {
  display: flex;
  align-items: center;
  &__Parallel {
    // min-width: 100px;
    // text-align: center;;
    span {
      font-family: "Avenir-Heavy";
    }
  }
  &__Lighthouse {
    height: 35px;
    margin-right: 10px;
    border-radius: 800px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: "Avenir-Heavy";

    &--neutral {
      // background-color: #c6c6c6;
      border: 1px solid;
      background-color: transparent;
      font-family: "Avenir-Regular";
      border-color: #c6c6c6;
      color: $black;
    }
    &--red {
      background-color: #d63643;
    }
    &--yellow {
      background-color: #fec32d;
    }
    &--green {
      background-color: #6bc235;
    }
  }
}

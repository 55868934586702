@import "../../global/styles/variables.scss";

.NotFound {
  &__Main {
    display: flex;
    position: relative;
  }

  &__PageTitle {
    font-size: 30px;
  }

  &__Content {
    overflow-y: scroll;
    height: calc(100vh - 76px); //TODO - Colocar variavel
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__Error {
    p {
      margin: 20px 0;
    }
  }
}

@import "../../global/styles/variables.scss";

.OPagination {
  ul {
    display: flex;
  }
  li {
    width: 55px;
    height: 55px;
    background-color: #f0f2f4;
    border-radius: 15px;
    margin-left: 10px;
    cursor: pointer;
    font-family: "Avenir-Roman";
    font-size: 18px;
    &:hover{
      opacity: .9;
      a{
        text-decoration: underline;
      }
    }
  }
  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  .selected {
    color: #fff;
    background-color: $orange;
    &:hover{
      opacity: 1;
      a{
        text-decoration: none;
      }
    }
  }
  .disabled{
    pointer-events: none;
    opacity: .5;
  }
  svg {
    width: 50%;
  }
}

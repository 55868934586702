.OCheckboxGroup {
  width: 100%;
  display: flex;
  &--column {
    flex-direction: column;
  }
  &--row {
    flex-direction: row;
  }
}

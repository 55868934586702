@import "../../global/styles/variables.scss";

.AddEdit {
  width: 100%;
  max-width: 900px;
  
  &__Main {
    position: relative;
  }

  .OLoading {
    z-index: 10000;
  }

  &__ContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__FormContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: flex-start;
    justify-content: space-between;
    gap: 10px;
  }

  .OInput {
    width: auto;
  }

  .OInput, .OSelect {
    width: 100%;
    max-width: 210px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__FullWidth {
      max-width: none;
      flex: 1 0 100%;
    }
  }

  .OSelect {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__ANIFilters {
    width: 100%;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: flex-start;
    display: none;
    gap: 10px;

    &--show {
      display: flex;
    }
  }

  &__Footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }

  &__StatusContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9999;

    &__Buttons {
      display: flex;
      gap: 20px;
    }
  }
}
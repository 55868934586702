@import "../../global/styles/variables.scss";

.AlertsHistory {
  &__Main {
    display: flex;
    position: relative;
  }

  .AlertIcon {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 5px;
    display: inline-block;
    box-sizing: border-box;
    line-height: 0;

    svg {
      path {
        stroke: #000;
      }
    }

    &.block {
      background: #ff0000;
      svg {
        path {
          stroke: #fff;
        }
      }
    }

    &.unblock {
      background: #00ff00;
    }
  }

  &__Menu {
    position: relative;
    min-width: 250px;
    height: calc(100vh - 76px); //TODO - Colocar variavel
    background-color: #363636;
    text-align: left;
    color: #fff;
    font-size: 18px;
    box-sizing: border-box;

    &__Content {
      overflow-y: auto;
      padding: 40px 20px 10px;
      height: calc(100vh - 76px);
      box-sizing: border-box;
    }
  }

  h1 {
    font-size: 30px;
    margin: 0 0 30px 0;
  }

  &__PageTitle {
    font-size: 30px;
    margin-bottom: 20px;
  }

  &__Title {
    margin-bottom: 10px;
  }

  &__Search {
    margin-top: 20px;
    width: 100%;
  }

  &__Content {
    overflow: scroll;
    height: calc(100vh - 76px); //TODO - Colocar variavel
    width: calc(100% - 250px);
    padding: 0 15px 20px 15px;
    box-sizing: border-box;
    text-align: left;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
      width: 100%;
      max-width: 1050px;
    }

    &__Full {
      width: 100%;
      min-height: 500px;
    }
  }

  &__HeaderContent {
    padding: 30px 0;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  &__Loading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    position: relative;
    margin: 10% 0;

    &__NoFilters {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 10;
    }
  }

  &__TableHeader {
    display: grid;
    font-size: 17px;
    font-family: "Avenir-Heavy";
    padding: 0 16px;
    margin-bottom: 10px;
    align-items: center;
  }

  .OTableGrid__Content, .OTableGrid__HeaderColumns {
    padding: 0 10px 0 10px;
    box-sizing: border-box;
  }

  &__Error {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #d63643;
    font-size: 18px;
    // text-align: center;
  }

  .OPagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
@import "../../global/styles/variables.scss";

.OProgressCircle {
  display: flex;
  align-items: center;
  &__Circle {
    width: 47px;
    position: relative;
    svg {
      transform: rotate(-90deg);
      stroke: $light-gray;
    }
  }

  &__Percent {
    stroke-dasharray: 100;
    stroke: $orange;
  }

  &__Progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
  }

  &__Label {
    margin-left: 10px;
  }

  &--green {
    .OProgressCircle__Percent {
      stroke: $lighthouse-green;
    }
    .OProgressCircle__Progress {
      color: $lighthouse-green;
    }
  }

  &--red {
    .OProgressCircle__Percent {
      stroke: $lighthouse-red;
    }
    .OProgressCircle__Progress {
      color: $lighthouse-red;
    }
  }
}

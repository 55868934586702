@import "./variables.scss";

.bold {
  font-family: "Avenir-Heavy";
}

.title {
  font-size: 36px;
  font-family: "Avenir-Heavy";
  color: $black;
}

.text {
  font-size: 22px;
  font-family: "Avenir-Roman";
  color: $black;
  line-height: 1.3;
}

.Icon {
  cursor: pointer;
  width: 28px;
  height: 28px;
  fill: #000;
  &:hover {
    fill: hsl(0, 0%, 80%);
  }
}

.OLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
}

i {
  font-style: italic;
}

.loading-input {
  position: relative;
  overflow: hidden;
  pointer-events: none;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: lighten($loading-anim, 15%);
    animation: loading-pulse 1.3s ease-out infinite;
  }
}

.loading-input:after {
  animation-delay: 0.15s;
}

@keyframes loading-pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.Csv{
  stroke: $orange;
}

.Red{
  color: $lighthouse-red;
}

.Green{
  color: #477e25;
}
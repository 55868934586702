@import "../../global/styles/variables.scss";

.OConnectTable {
  &__TitleContainer {
    display: flex;
    align-items: center;
  }
  &__Icon {
    width: 25px;
    svg {
      fill: $lighthouse-green;
    }
  }
  &__TitleContainer,
  &__Info {
    margin-bottom: 20px;
  }
  &__Title {
    font-size: 20px;
    font-weight: bold;
  }

  &__Info {
    display: flex;
    .OProgressCircle{
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

@import "../../global/styles/variables.scss";

.OAni {
  width: 100%;
  &__TitleAni {
    color: #000;
    font-size: 26px;
  }

  &__AniOptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__AniMenu {
    text-decoration: underline;
    color: rgb(56, 56, 252);
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }

  &__Indicators {
    display: flex;
    justify-content: space-between;
    .OMonitorItem {
      width: 32%;
    }
  }

  &__Separator {
    width: 50%;
    height: 1px;
    background-color: #e0e0e0;
    margin: 30px auto;
  }

  &__DetailsMenu {
    display: flex;
    margin-bottom: 40px;
    li {
      margin-right: 20px;
      cursor: pointer;
      &:hover{
        color: #ff6804;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__DetailsMenuActive {
    position: relative;
    padding-bottom: 10px;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: -1px;
      background-color: #ff6804;
    }
  }

  &__TitleMain {
    color: #000;
    font-size: 26px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  &__Details {
    height: 0;
    overflow: hidden;
    &--open {
      height: 100%;
    }
  }
}

@import "../../global/styles/variables.scss";

.OUser {
  position: relative;
  &__Container {
    display: flex;
    align-items: center;
  }
  &__Profile {
    width: 40px;
    height: 40px;
    background-color: #fc6821;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: #fff;
    font-size: 1.5em;
    margin-right: 20px;
    font-family: "Avenir-Heavy";
  }
  &__Name {
    font-size: 18px;
    color: #686868;
    font-family: "Avenir-Roman";
  }
  &__Arrow {
    margin-left: 20px;
    width: 15px;
    height: 11px;
  }
  &__Doll{
    width: 50%;
    fill: #fff;
  }
  &__Menu {
    position: absolute;
    top: 76px;
    right: 0;
    background-color: #fff;
    width: 100%;
    padding: 20px;
    text-align: left;
    color: #686868;
    font-family: "Avenir-Roman";
    font-size: 18px;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 99;
    li {
      margin-bottom: 15px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover{
        text-decoration: underline;
      }
    }
    &--hide{
      display: none;
    }
  }

  &--interaction {
    cursor: pointer;
    &:hover {
      .OUser__Name {
        text-decoration: underline;
      }
    }
  }
}

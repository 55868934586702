@import "../../global/styles/variables.scss";

$gap: 20px;

.OTableGrid {
  width: 100%;
  height: 100%;
  color: $black;
  font-size: 16px;

  &__Header, &__Body {
    // grid-template-columns: repeat(auto-fill, auto);
    display: flex;
  }

  table {
    table-layout: fixed;

    thead {
      border-bottom: 3px solid $light-gray;
      padding-bottom: 5px;
    }

    th {
      font-size: 16px;
      font-family: "Avenir-Heavy";
      // &:first-child {
      //   word-break: normal;
      // }
    }

    tbody {
      tr {
        border-bottom: 3px solid $light-gray;

        &:nth-child(odd) {
          background-color: #fafafa;
        }
        &:nth-child(even) {
          background-color: #f1f1f1;
        }

        &:last-child {
          border: none;
          // font-weight: bold;
        }
      }
    }

    th, td {
      padding: 10px;
      max-width: 400px;
    }

    td {
      // word-break: break-all;
      // padding: 10px 0;

      &:first-child {
        word-break: normal;
      }
    }
  }

  &__Body {
    flex-direction: column;
  }

  &__Columns {
    display: flex;
  }

  &__Header {
    // display: grid;
    border-bottom: 3px solid $light-gray;
    padding-bottom: 5px;
    box-sizing: border-box;
    // gap: $gap;
    div {
      font-size: 16px;
      font-family: "Avenir-Heavy";
    }
    &--ScrollY {
      padding-right: calc(20px + $scrollBar);
    }
  }
  &__Columns,
  &__HeaderColumns {
    text-align: left;
  }

  &__Columns {
    // display: grid;
    box-sizing: border-box;
    border-bottom: 3px solid $light-gray;
    // padding: 10px 0;
    // gap: $gap;
    // align-items: center;
    &--highlightsLast {
      &:last-child {
        border: none;
        font-weight: bold;
      }
    }
    &--click {
      cursor: pointer;
      &:hover {
        background-color: #fafafa;
      }
    }
    &:nth-child(odd) {
      background-color: #fafafa;
    }
    &:nth-child(even) {
      background-color: #f1f1f1;
    }
  }

  &__Body {
    // display: grid;
    &--ScrollX {
      overflow-x: scroll;
    }
    &--ScrollY {
      overflow-y: scroll;
      padding-right: 20px;
    }
  }

  &__Content {
    &--textOverflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--breakWord {
      word-break: break-all;
    }
  }

  &__noDataMessage {
    text-align: center;
    padding: 30px;
  }
}

@import "../../global/styles/variables.scss";

.OMenuItem {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  opacity: 0.5;
  &__Label {
    font-size: 26px;
    font-family: "Avenir-Heavy";
    color: $black;
    letter-spacing: 0px;
    transition-property: letter-spacing;
    transition-duration: 0.4s;
  }
  &__Rec {
    height: 100%;
    width: 6px;
    border-radius: 80px;
    background-color: $orange;
  }
  &--activie {
    opacity: 1;
  }
  &:hover {
    .OMenuItem__Label {
      letter-spacing: 1px;
    }
  }
}

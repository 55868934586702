.container {
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 18px;
  user-select: none;
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;

  .label {
    position: relative;
    top: 3px;
  }
}

.container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #dadada;
  border-radius: 5px;
}

.container:hover input ~ .checkmark {
  border-color: #000;
}

.container input:checked ~ .checkmark {
  background-color: #fff;
  border-color: #000;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

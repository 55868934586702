@import "../../global/styles/variables.scss";

.OTabItem {
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-between;
  cursor: pointer;
  &__Label {
    font-size: 22px;
    &--activie {
      color: #43bbf2;
    }
  }
  &__Underline {
    width: 100%;
    height: 3px;
    background-color: #43bbf2;
  }
}

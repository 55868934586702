@import "../../global/styles/variables.scss";

.OToogle {
  input[type="checkbox"] {
    position: relative;
    width: 32px;
    height: 21px;
    -webkit-appearance: none;
    outline: none;
    border-radius: 20px;
    border: 2px solid $gray;
    margin: 0;

    // circle
    &::before {
      position: absolute;
      content: "";
      width: 13px;
      height: 13px;
      top: 50%;
      left: 2px;
      border-radius: 20px;
      background-color: $gray;
      transition: left 100ms linear;
      transform: translateY(-50%);
    }

    &:checked {
      &::before {
        left: 13px;
      }
    }
  }

  &--Change {
    cursor: pointer;
  }

  &--orange {
    input[type="checkbox"] {
      &:checked {
        border-color: $orange;
        &::before {
          background-color: $orange;
        }
      }
    }
  }

  &--white {
    input[type="checkbox"] {
      &:checked {
        border-color: $white;
        &::before {
          background-color: $white;
        }
      }
    }
  }
}

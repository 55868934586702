@import "../../global/styles/variables.scss";

.OPeriod {
  &__Title {
    margin-bottom: 10px;
  }
  .DatePicker {
    margin-bottom: 10px;
  }
}

@import "../../global/styles/variables.scss";

.OAniGrid {
  width: 100%;
  border-bottom: 1px solid #cccccc;
  padding: 0 16px;
  box-sizing: border-box;
  &--open {
    background-color: #f5f5f5;
  }
  &--Red {
    background-color: #fabeaf;
    .OAniGrid__SeeDetails__Label {
      color: #000000;
    }
  }
  &__Content {
    color: #292929;
    height: 46px;
    display: grid;
    align-items: center;
    font-size: 16px;
  }
  &__Details {
    height: 0;
    overflow: hidden;
    &--open {
      height: 100%;
    }
  }

  &__SeeDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &__Label {
      color: #fc6821;
      font-family: "Avenir-Heavy";
      text-decoration: underline;
      user-select: none;
      cursor: pointer;
      &:hover {
        color: #43bbf2;
      }
    }
  }

  &__DetailsContent {
    padding: 20px 0;
  }

  &__DetailsMenuActive {
    color: #43bbf2 !important;
  }

  &__DetailsTable {
    grid-column: span 8;
  }

  &__CloseIcon {
    width: 24px;
    margin-left: 10px;
  }

  &__Lock {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__LockCircle {
    width: 12px;
    height: 12px;
    border-radius: 8px;
    &--Red {
      background-color: #d63643;
    }
    &--Green {
      background-color: #6bc235;
    }
  }

  &__DetailsMenu {
    margin-bottom: 20px;
    ul {
      display: flex;
    }
    li {
      cursor: pointer;
      color: #000000;
      font-family: "Avenir-Heavy";
      text-decoration: underline;
      user-select: none;
      font-size: 16px;
      margin-right: 26px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #43bbf2;
      }
    }
  }
}

@import "../../global/styles/fonts.scss";

.Login {
  width: 100vw;
  height: 100vh;
  display: flex;
  min-width: 800px;
  align-items: center;
  justify-content: center;
  background: #363636;

  &__Box {
    display: flex;
    padding: 2.5rem 8rem;
    position: relative;
    flex-direction: column;
    background-color: #fff;
    h1 {
      font-size: 24px;
      font-weight: normal;
      font-family: "Avenir-Regular";
      span {
        font-weight: 700;
        font-family: "Avenir-Heavy";
      }
    }
  }

  &__Info {
    width: fit-content;
    margin: 0 auto 20px;
    text-align: right;
  }

  &__Form {
    gap: 1.2rem;
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__LogoBox {
    top: 0;
    left: -60px;
    width: 60px;
    height: 60px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #ff6804;
    svg {
      width: 55%;
      margin-right: 2px;
    }
  }

  &__NewPasswordForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      strong {
        font-weight: bold;
      }
    }

    > p, .OInput {
      margin-bottom: 20px;
    }
  }

  
  &__ButtonSave {
    display: flex;
    &__ProviderButtons {
      display: flex;
      flex-direction: column;
    }
    &__OtherOptions {
      width: 80%;
      border-top: 1px solid #333;
      height: 1px;
      margin: 0 0 20px 0;
      align-self: center;
    }
  }

  &__Title {
    text-align: center;
    &--error {
      color: #d90f0f;
      line-height: 1.5em;
    }
  }

  &__Version {
    font-size: 10px;
    font-family: "Avenir-Regular" !important;
    font-weight: normal !important;
    margin-top: 5px;
  }

  &__AWS_Error {
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      margin-bottom: 20px;
      color: #d90f0f;
    }
  }
}

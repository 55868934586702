body{
  overflow: hidden;
}

.App {
  text-align: center;
  overflow: hidden;
}

.OModal.AuthErrorModal {
  z-index: 100000000;
  .OModal__Container {
    text-align: center;
  }
}

.OModal.RequestErrorModal {
  z-index: 100000000;
  .OModal__Container {
    text-align: center;
  }

  .RequestErrorMessage {
    overflow: hidden;
    width: 100%;
    max-width: 1000px;
    text-align: center;
    margin: 0 auto;

    .RequestErrorDescription {
      box-sizing: border-box;
      padding: 10px;
      background-color: #cccccc;
      border-radius: 10px;
      font-size: 12px;
      margin: 10px;
      // width: 100%;
      word-wrap: break-word;
      // overflow: scroll;
    }

    .RequestErrorButton {
      display: inline-block;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.DatePicker {
  p{
    font-size: 20px;
    margin-bottom: 10px;
  }
  .react-datepicker__input-container {
    input {
      width: 100%;
      border: 1px solid #707070;
      border-radius: 5px;
      height: 54px;
      padding: 2px 8px;
      box-sizing: border-box;
      font-size: 16px;
      font-family: "Avenir-Regular";
    }
  }
}

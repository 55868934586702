@import "../../global/styles/variables.scss";
@import "../../global/styles/common.scss";

.Header {
  width: 100%;
  height: $headerH;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 70px;
  padding-left: 20px;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1000;
  position: relative;
  &__Logo {
    display: flex;
    align-items: center;
    background-color: #ff6804;
    height: 55px;
    width: 55px;
    justify-content: center;
    border-radius: 15px;
    margin-right: 50px;
  }
  &__LogoContainer {
    display: flex;
    height: 100%;
    align-items: center;
  }
  h1 {
    font-size: 30px;
    color: $black;
    margin-left: 30px;
  }
  &__Olos {
    width: 60%;
    height: 60%;
    path {
      fill: $white;
    }
  }
  &__Menu {
    display: flex;
  }
  &__MenuItem {
    margin-right: 50px;
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 0;
    }
  }

  &__Container {
    display: flex;
    align-items: center;
    .OUser {
      margin-left: 40px;
    }
  }
}

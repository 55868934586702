@import "../../global/styles/variables.scss";

.OModal {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;

  &__Container {
    position: relative;
    background-color: #fff;
    padding: 60px 40px 40px;
    box-sizing: border-box;
    box-shadow: 0px 3px 9px rgba(26, 26, 26, 0.25);
    border-radius: 5px;
    text-align: center;
    max-width: 95vw;
    min-height: 265px;
    text-align: left;
    overflow: hidden;
    max-height: 95vh;
    h1 {
      margin-bottom: 10px;
      font-size: 22px;
      font-family: "Avenir-Heavy";
    }
    p {
      color: #363636;
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 1.5;
      font-family: "Avenir-Roman";
      b{
        font-family: "Avenir-Heavy";
        font-weight: 700;
      }
    }
  }
  &__Info {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
  }
  &__Alert{
    width: 50px;
    color: red;
    margin-bottom: 10px;
  }
  &__Close {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
  &__Children {
    height: 100%;
  }
}

.AlertsSidebar {
  width: 12%;
  min-width: 250px;
  background-color: #363636;

  &__PageTitle {
    position: sticky;
    top: 0;
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 20px;
    // margin-bottom: 20px;
    color: #fff;
    text-align: left;
    z-index: 10;
    background-color: #363636;
  }

  &__NoAlerts {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
  }

  .AlertItem {
    cursor: pointer;
    position: relative;
    text-align: left;
    background: #ccc;
    opacity: 0.5;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
    margin-bottom: 10px;

    &:hover {
      opacity: 1;
    }

    &.new-alert {
      background: #fff;
      opacity: 1;
    }

    li {
      margin-bottom: 5px;
      font-size: 12px;

      &.AlertItem__Title {
        font-size: 14px;
      }
      span {
        font-weight: bold;
      }
    }

    &__Icon {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      padding: 5px;
      display: inline-block;
      box-sizing: border-box;
      line-height: 0;

      svg {
        path {
          stroke: #000;
        }
      }

      &.block {
        background: #ff0000;
        svg {
          path {
            stroke: #fff;
          }
        }
      }

      &.unblock {
        background: #00ff00;
      }
    }
  }

  &__Menu {
    position: relative;
    width: 22%;
    height: calc(100vh - 76px); //TODO - Colocar variavel
    background-color: #363636;
    text-align: left;
    color: #fff;
    font-size: 18px;
    box-sizing: border-box;

    &__Content {
      overflow-y: auto;
      padding: 0 20px 90px;
      height: calc(100vh - 76px);
      box-sizing: border-box;
    }

    .OLoading {
      color: #000;
    }
  }
}
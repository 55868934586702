// Sizes
$headerH: 76px;
$menuW: 15%;
$contentW: 100% - $menuW;
$minContentH: calc(100vh - $headerH - 40px);
$scrollBar: 17px;

//Spacing
$sides: 63px;
$topH: 40px;
$topContent: 60px;
$sidesContent: $sides + 56px + 20px;

// Colors

$orange: #ff6804;
$white: #ffffff;
$black: #363636;
$light-gray: #efefef;
$bold-gray: #737373;
$bold-gray-2: #707070;
$gray: #999999;
$gray-2: #b3b3b3;
$red: #ff0000;
$loading-bg: #ccc;
$loading-anim: #eee;
$lighthouse-red: #d63643;
$lighthouse-yellow: #fec32d;
$lighthouse-green: #6bc235;

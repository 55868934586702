.Config {
  width: 60vw;
  height: 55vh;

  &__Container {
    margin-top: 20px;
    height: calc(100% - 20px);
  }

  .OInputForm__Label,
  .OSelect__Label {
    color: #686868;
    font-family: "Avenir-Heavy";
  }

  .OTabs {
    margin-top: 30px;
  }
}

@import "../../global/styles/variables.scss";

.OIconButton {
  // width: 100%;
  // height: 40px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  background-color: $orange;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  // text-transform: uppercase;
  padding: 10px 15px;
  box-sizing: border-box;
  // font-weight: bold;
  border: 0;

  &--no-label {
    svg {
      margin-left: 0;
    }
  }
  
  &__Icon {
    width: 20px;
    height: 20px;
    fill: $white;
    stroke: $white;
    margin-left: 12px;

    path {
      fill: $white;
      stroke: $white;
    }
  }
  &--disable {
    color: $gray;
    background-color: $light-gray;
    .OIconButton__Icon {
      fill: $gray;
    }
  }
  &--danger {
    background-color: #f00;

  }
}

.Button {
  width: 260px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 6px;
  font-family: 'Avenir-Regular';
  
  font-size: 20px;
  box-sizing: border-box;
  cursor: pointer;

  &:disabled {
    background-color: #efefef;
    color: #999999;
    cursor: default;
    opacity: .5;
  }

  &--Orange {
    background-color: #ff6804;
    color: #fff;
  }

  &--Danger {
    color: #fff;
    background-color: #f00;
  }

  &--Border{
    background-color: #fff;
    color: #737373;
    border: 3px solid #737373;
  }

  &--Small{
    font-size: 16px;
    height: 40px;
    width: 240px;
  }
}

.buttonSearch {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff6804;
  border-radius: 50%;
  border: none;

  svg {
    font-size: 20px;
    color: #fff;
  }
}

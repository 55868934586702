@import "../../global/styles/variables.scss";

.OAction {
  position: relative;
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;

  &__Active {
    border-bottom: 2px solid $orange;
  }

  &__Label {
    cursor: pointer;
    color: $bold-gray;
    font-size: 18px;
    font-family: "Avenir-Heavy";
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__Icon {
    width: 30px;
    margin-right: 10px;
    svg {
      fill: $bold-gray;
    }
  }
  &__Indicator {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: $orange;
    margin-left: 10px;
  }

  &__Children {
    position: absolute;
    padding-top: 9px;
    z-index: 20;
    left: -20px;
  }

  &__Triangle {
    width: 0px;
    height: 0px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid #fff;
    position: relative;
    overflow: hidden;
  }

  &__ChildrenContainer {
    background-color: #fff;
    padding: 30px 20px;
    border-radius: 5px;
    box-shadow: 0px 6px 20px 4px rgba(0, 0, 0, 0.2);
    min-width: 300px;
    text-align: left;
    display: grid;
  }

  &__Sub{
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 10px;
    min-width: 350px;
    line-height: 1.1;
    &:hover{
      background-color: $light-gray;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }

  &__SubIcon{
    width: 40px;
    height: 40px;
    background-color: $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-right: 10px;
    svg{
      width: 70%;
      fill: $orange;
      opacity: .7;
    }
  }

  &__SubText{
    font-family: "Avenir-Roman";
    color: $black;
    margin-bottom: 5px;
  }

  &__SubDescription{
    color: $gray-2;
    font-size: 12px;
  }

  &:hover {
    .OAction__Label {
      color: $black;
    }

    .OAction__Icon {
      svg {
        fill: $black;
      }
    }
  }
}
